import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import UserHome from './components/UserHome';
import TaskPage from './components/TaskPage';
import EditTaskPage from './components/EditPage';
import './styles.css';
import TaskView from './components/TaskView';
const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/user-home" element={<UserHome />} />
      <Route path="/task-view" element={<TaskView />} />
      <Route path="/add-task" element={<TaskPage />} />
      <Route path="/edit-task/:taskId" element={<EditTaskPage />} />
    </Routes>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
