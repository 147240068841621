import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Task from '../utils/TaskMaster'; // Assuming Task is imported from utils

const TaskView = () => {
  const location = useLocation();
  const [tasks, setTasks] = useState([]);
  const [leafTasks, setLeafTasks] = useState([]);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const navigate = useNavigate();

  // Retrieve the taskId from the location state
  const { taskId } = location.state || {};

  useEffect(() => {
    if (taskId) {
      // Make the API request to the backend to get task details using fetch
      fetch(`https://dopatize.com/api/task/${taskId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' // if needed
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Convert the JSON data into a Task object
          const task = new Task(data);
          setTasks(task);
          // Get all leaf subtasks
          setLeafTasks(task.getLeafSubtasks({ complete: false }));
        })
        .catch(error => {
          // Handle errors
          console.error('Error fetching the task data:', error);
        });
    }
  }, [taskId]);

  const handleNextTask = () => {
    if (currentTaskIndex < leafTasks.length - 1) {
      setCurrentTaskIndex(currentTaskIndex + 1);
    }
  };

  const handleCompleteTask = async () => {
    try {
      const currentTask = leafTasks[currentTaskIndex];
      currentTask.complete=new Date();
      const response = await fetch(`https://dopatize.com/api/task/${currentTask.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(currentTask.toJSON()),
        credentials: 'include',
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Task updated successfully", data);
      } else {
        console.error("Failed to update task", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    handleNextTask(); // Move to the next task upon completion
  };

  const handleBackToHome = () => {
    navigate('/user-home');
  };

  return (
    <div>
      {leafTasks.length > 0 ? (
        <div>
          <h2>Task Details</h2>
          <p>{leafTasks[currentTaskIndex].title}</p>
          <button
            onClick={handleNextTask}
            disabled={currentTaskIndex >= leafTasks.length - 1}
          >
            Next Task
          </button>
          <button onClick={handleCompleteTask}>Completed</button>
          <button onClick={handleBackToHome}>Back to Home</button>
        </div>
      ) : (
        <p>Loading task data...</p>
      )}
    </div>
  );
};

export default TaskView;



