import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Task from '../utils/TaskMaster';
import { useNavigate } from 'react-router-dom';

const TaskForm = ({ onSubmit, initialTask = null }) => {
    const [task, setTask] = useState(initialTask || new Task({
        id: uuidv4(),
        user_id: 1,
        creation_date: new Date(),
        title: '',
        parent_id: null,
        category: '',
        complete: null,
        frequency: 0,
        working_time: 0,
        importance: 1,
        due_date: null,
        subtasks: []
    }));
    
    const navigate = useNavigate();

    const handleTaskChange = (e, taskId) => {
        const { name, value, type } = e.target;
        const updatedTask = task.getTask(taskId);

        if (updatedTask) {
            updatedTask[name] = (type === 'date') ? new Date(value) : value;
            setTask(new Task(task.toJSON()));
        }
    };

    const addSubtask = (parentId) => {
        const parentTask = task.getTask(parentId);

        if (parentTask) {
            const newSubtask = {
                id: uuidv4(),
                user_id: parentTask.user_id,
                creation_date: new Date(),
                title: '',
                parent_id: parentId,
                category: '',
                complete: null,
                frequency: 0,
                working_time: 0,
                importance: parentTask.importance,
                due_date: parentTask.due_date,
                subtasks: []
            };

            parentTask.addSubtask(newSubtask);
            setTask(new Task(task.toJSON()));
        }
    };

    const deleteSubtask = (taskId, subtaskId) => {
        const parentTask = task.getTask(taskId);

        if (parentTask) {
            parentTask.subtasks = parentTask.subtasks.filter((subtask) => subtask.id !== subtaskId);
            setTask(new Task(task.toJSON()));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onSubmit(task);
        navigate('/user-home');
    };

    const renderTaskForm = (taskId) => {
        const currentTask = task.getTask(taskId);
        if (!currentTask) return null;

        return (
            <div style={{ marginLeft: currentTask.parent_id ? '20px' : '0px' }}>
                <h3>{currentTask.parent_id ? 'Subtask' : 'Parent Task'}</h3>
                <div>
                    <label>Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={currentTask.title}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                        required
                    />
                </div>
                <div>
                    <label>Category:</label>
                    <input
                        type="text"
                        name="category"
                        value={currentTask.category}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                    />
                </div>
                <div>
                    <label>Frequency (every how many days):</label>
                    <input
                        type="number"
                        name="frequency"
                        value={currentTask.frequency ?? 0}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                        min="0"
                    />
                </div>
                <div>
                    <label>Importance (1-10):</label>
                    <input
                        type="number"
                        name="importance"
                        value={currentTask.importance ?? 1}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                        min="1"
                        max="10"
                    />
                </div>
                <div>
                    <label>Due Date:</label>
                    <input
                        type="date"
                        name="due_date"
                        value={currentTask.due_date ? currentTask.due_date.toISOString().substr(0, 10) : ''}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                    />
                </div>

                {/* Render subtasks recursively */}
                {currentTask.subtasks?.map((subtask) => (
                    <div key={subtask.id}>
                        {renderTaskForm(subtask.id)}
                        {/* Delete button for each subtask */}
                        <button
                            type="button"
                            onClick={() => deleteSubtask(currentTask.id, subtask.id)}
                            style={{ marginTop: '5px' }}
                        >
                            Delete Subtask
                        </button>
                    </div>
                ))}

                {/* Button to add a new subtask at the bottom of the current task form */}
                <button type="button" onClick={() => addSubtask(currentTask.id)} style={{ marginTop: '10px' }}>
                    Add Subtask
                </button>
            </div>
        );
    };

    return (
        <form onSubmit={handleSubmit}>
            {renderTaskForm(task.id)}
            <button type="submit">{initialTask ? 'Update Task' : 'Create Task'}</button>
        </form>
    );
};

export default TaskForm;
