import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TaskForm from './TaskForm'; // Import the TaskForm component
import Task from '../utils/TaskMaster'; // Import the Task class



const EditTaskPage = () => {
  const { taskId } = useParams(); // Get the taskId from the URL
  const [taskToEdit, setTaskToEdit] = useState(null); // State to hold the task to be edited
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // Fetch the task data from the back end
    fetch(`https://dopatize.com/api/task/${taskId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies if required
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Fetched task data:', data);
      const task = new Task(data); // Convert the JSON data into a Task object
      setTaskToEdit(task); // Set the task to edit
    })
    .catch(error => {
      console.error('Error fetching the task data:', error);
      // Optionally, navigate back or show an error message
    });
  }, [taskId]);

  // Log taskToEdit after it is set
  useEffect(() => {
    if (taskToEdit) {
      console.log('Task to edit:', taskToEdit);
    }
  }, [taskToEdit]);

  const handleTaskSubmit = (task) => {
    console.log('Task Data:', task);

    // Assuming task is an instance of TaskMaster with a toJSON method
    const taskData = task.toJSON();
    console.log('Task Data:', taskData);

    // Send the task data to the backend using fetch
    fetch(`https://dopatize.com/api/task/${taskId}`, { // Use taskId for editing
      method: 'Post', // Use PUT for updating an existing task
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(taskData),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      navigate('/user-home'); // Redirect to home after successful update
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <div>
      <h2>{taskToEdit ? 'Edit Task' : 'Create New Task'}</h2> {/* Conditional title */}
      {taskToEdit ? ( // Ensure form is only rendered once the task is fetched
        <TaskForm onSubmit={handleTaskSubmit} initialTask={taskToEdit} />
      ) : (
        <div>Loading task...</div> // Show a loading message until the task is fetched
      )}
    </div>
  );
};

export default EditTaskPage;
