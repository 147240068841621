import React from 'react';
import TaskForm from './TaskForm';

const TaskPage = () => {
  const handleTaskSubmit = (task) => {
    console.log('Task Data:', task);

    // Assuming task is an instance of TaskMaster with a toJSON method
    const taskData = task.toJSON();
    console.log('Task Data:', taskData);

    // Send the task data to the backend using fetch
    fetch('https://dopatize.com/api/tasks', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(taskData),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <div>
      <h2>Create New Task</h2>
      <TaskForm onSubmit={handleTaskSubmit} />
    </div>
  );
};

export default TaskPage;
