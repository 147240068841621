import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Task from '../utils/TaskMaster'; // Assuming Task class is imported from Task.js


const UserHome = () => {
  const [tasks, setTasks] = useState([]);
  const [nestedView, setNestedView] = useState(false);
  const [importanceView, setImportanceView] = useState(false); // New state for importance view
  const [priorityTasks, setPriorityTasks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch('https://dopatize.com/api/user-home', {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }

        const taskList = await response.json();
        const taskInstances = taskList.map(task => new Task(task));
        setTasks(taskInstances);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        alert('Failed to load the tasks. Please try again.');
      }
    };

    fetchTasks();
  }, []);

  // Calculate and sort tasks by priority
  const calculateAndSortByPriority = () => {
    const sortedTasks = tasks
      .map(task => ({
        task,
        priority: task.getParentTasksByLeafPriority(),
      }))
      .flatMap(({ task, priority }) =>
        priority.map(p => ({
          ...task.getTask(p.parent_id),
          priority: p.priority,
        }))
      )
      .sort((a, b) => a.priority - b.priority);

    setPriorityTasks(sortedTasks);
  };

  // Handle toggling the importance view
  const toggleImportanceView = () => {
    if (!importanceView) calculateAndSortByPriority();
    setImportanceView(!importanceView);
  };

  // Handle file upload for tasks
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await fetch('https://dopatize.com/api/upload', {
          method: 'POST',
          credentials: 'include',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }

        const newTasks = await response.json();
        setTasks(newTasks.map(task => new Task(task)));
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to load the tasks. Please try again.');
      }
    }
  };

  // Open the menu for a specific task
  const handleMenuOpen = (event, taskId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTask(taskId);
  };

  // Close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTask(null);
  };

  // Handle task edit
  const handleEditClick = () => {
    if (selectedTask) {
      navigate(`/edit-task/${selectedTask}`);
    }
    handleMenuClose();
  };

  // Navigate to task view
  const handleTaskClick = (taskId) => {
    navigate('/task-view', { state: { taskId } });
  };

  // Render nested tasks recursively
  const renderNestedTasks = (tasks, level = 0) => {
    return tasks.map((task) => (
      <div key={task.id} style={{ marginLeft: `${level * 20}px` }} className="task-item">
        <Button
          onClick={() => handleTaskClick(task.id)}
          variant="contained"
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          {task.title}
          <IconButton
            aria-controls="task-menu"
            aria-haspopup="true"
            onClick={(event) => handleMenuOpen(event, task.id)}
            size="small"
            style={{ padding: '0 5px' }}
          >
            <MoreVertIcon />
          </IconButton>
        </Button>
        {task.subtasks && task.subtasks.length > 0 && (
          <div>{renderNestedTasks(task.subtasks, level + 1)}</div>
        )}
      </div>
    ));
  };

  // Render top-level (flat) tasks
  const renderFlatTasks = () => {
    const topLevelTasks = tasks.filter(task => task.parent_id === null);
    return topLevelTasks.map((task) => (
      <div key={task.id} className="task-item">
        <Button
          onClick={() => handleTaskClick(task.id)}
          variant="contained"
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          {task.title}
          <IconButton
            aria-controls="task-menu"
            aria-haspopup="true"
            onClick={(event) => handleMenuOpen(event, task.id)}
            size="small"
            style={{ padding: '0 5px' }}
          >
            <MoreVertIcon />
          </IconButton>
        </Button>
      </div>
    ));
  };

  // Render tasks based on priority
  const renderPriorityTasks = () => {
    return priorityTasks.map((task) => (
      <div key={task.id} className="task-item">
        <Button
          onClick={() => handleTaskClick(task.id)}
          variant="contained"
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          {task.title}
          <IconButton
            aria-controls="task-menu"
            aria-haspopup="true"
            onClick={(event) => handleMenuOpen(event, task.id)}
            size="small"
            style={{ padding: '0 5px' }}
          >
            <MoreVertIcon />
          </IconButton>
        </Button>
      </div>
    ));
  };

  // Render either nested or flat tasks
  const renderTasks = () => {
    return nestedView ? renderNestedTasks(tasks) : renderFlatTasks();
  };

  return (
    <div>
      <h1>Welcome to your Task Manager</h1>
      <Button variant="outlined" onClick={() => setNestedView(!nestedView)}>
        Toggle {nestedView ? 'Flat' : 'Nested'} View
      </Button>
      <Button variant="outlined" onClick={toggleImportanceView}>
        Toggle {importanceView ? 'Regular' : 'Importance'} View
      </Button>
      <Button variant="outlined" onClick={() => navigate("/add-task")}>
        Add Task
      </Button>

      <div id="task-buttons-container">
        {importanceView ? renderPriorityTasks() : renderTasks()}
      </div>

      <input type="file" onChange={handleFileChange} />

      {/* MUI Menu for task actions */}
      <Menu
        id="task-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        {/* Add more menu items like Delete, Complete, etc. */}
      </Menu>
    </div>
  );
};

export default UserHome;
